<template>
  <div style="margin-top: 30px">
    <SectionTitle :title="useStorePermission ? '區間內統計' : '點數紀錄列表'" fontSize="18" hideBtn />
    <div class="record__datetime">
      <RangeTimeSelect
        @custom="handleTimeRangeChange"
        @month="handleTimeRangeChange"
        @week="handleTimeRangeChange"
        @today="handleTimeRangeChange"
      />
    </div>

    <SectionTitle
      title="總覽"
      fontSize="18"
      hideBtn
      hideTitleStick
      style="margin-top: 20px"
    />
    <TimeRangeStatistic :dateRange="curDate" />

    <!-- <PointRecordStoreTable v-if="useStorePermission" :dateRange="curDate" /> -->

    <SectionTitle
      title="點數紀錄列表"
      fontSize="18"
      hideBtn
      style="margin-top: 20px"
    />
    <FiltersContainer class="mt-[20px]">
      <MemberSearch :model.sync="search.member" @change="refresh" />
      <el-select v-model="search.origin" clearable placeholder="請選擇形式" @change="refresh">
        <el-option
          v-for="option in originConfig"
          :key="option.value"
          :label="option.name"
          :value="option.value"
        />
      </el-select>
    </FiltersContainer>

    <el-table v-loading="loading.table" :data="tableData">
      <el-table-column prop="Member.UserInfo.name" label="姓名" align="center" />
      <el-table-column prop="Member.UserInfo.phone" label="電話號碼" align="center" />
      <el-table-column prop="origin" label="形式" align="center">
        <template slot-scope="scope">
          <div>{{ get(originConfig, `${scope.row.origin}.name`) || '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="點數" align="center">
        <template slot-scope="scope">
          <div><span v-if="scope.row.amount > 0">+</span>{{ scope.row.amount }}</div>
        </template>
      </el-table-column>
      <el-table-column label="建立時間" align="center">
        <template slot-scope="scope">
          <div>{{ dateFormat(scope.row.createdAt) }}</div>
        </template>
      </el-table-column>
      <el-table-column v-if="useStorePermission" props="Branch" label="門市" align="center">
        <template slot-scope="scope">
          {{ scope.row.Branch?.name || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="originMessage" label="備註" align="center" />
    </el-table>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(curDate)"
    />

    <ExportOptionsDialog
      v-if="dialog.exportOptions"
      allRange
      @close="dialog.exportOptions = false"
      @export="dialog.exportOptions = false, prepareExport()"
    />
    <ExportDialog
      v-if="exportState.exportting"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :percentage="exportState.percentage"
      :data="exportState.exportData"
      :total="exportState.dataCount"
      @close="resetExport"
    />
  </div>
</template>

<script setup>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import RangeTimeSelect from '@/components/Report/RangeTimeSelect.vue'
import TimeRangeStatistic from './TimeRangeStatistic.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
// import PointRecordStoreTable from './PointRecordStoreTable'
import { GetPointRecord, GetPointRecordCount } from '@/api/shopPoint'
import { onMounted, ref, computed } from 'vue'
import dayjs from '@/lib/dayjs'
import { get } from 'lodash'
import { useRangeTime } from '@/use/rangeTime'
import { useTable } from '@/use/table'
import { useExport } from '@/use/export'
import { originConfig } from '@/config/point'
import { useVModels } from '@vueuse/core'
import { usePermissions } from '@/use/permissions'

const props = defineProps({
  dialog: {
    type: Object,
    default: () => ({
      exportOptions: false,
      exporting: false,
    }),
  },
})

const emit = defineEmits(['update:dialog'])

const { search, loading, shopId, dateFormat, pageStartIndex, extendData, tableOptions, tableData, tableDataCount } = useTable()
const { getTodayRange } = useRangeTime()
const { exportState, resetExport, ExportExcel } = useExport()
const { dialog } = useVModels(props, emit)
const { checkAction } = usePermissions()

extendData('search', {
  origin: null,
  member: null,
})

extendData('dialog', {
  exportOptions: false,
  exporting: false,
})
const curDate = ref(getTodayRange())

const handleTimeRangeChange = async (e) => {
  tableOptions.page = 1
  await refresh(e)
}

const getPointRecord = async (date) => {
  const [res, err] = await GetPointRecord({
    shopId: shopId.value,
    createStart: dayjs(date[0]).toDate(),
    createEnd: dayjs(date[1]).toDate(),
    start: pageStartIndex.value,
    limit: tableOptions.pageLimit,
    origin: get(search, 'origin') || undefined,
    memberId: get(search, 'member.id') || undefined,
  })
  if (err) return (window.$message.error(err))
  tableData.value = res
}

const getPointRecordCount = async (date) => {
  const [res, err] = await GetPointRecordCount({
    shopId: shopId.value,
    createStart: dayjs(date[0]).toDate(),
    createEnd: dayjs(date[1]).toDate(),
    start: pageStartIndex.value,
    limit: tableOptions.pageLimit,
    origin: search.origin || undefined,
    memberId: get(search, 'member.id') || undefined,
  })
  if (err) return (window.$message.error(err))
  tableDataCount.value = res.count
}

const refresh = async (e) => {
  loading.table = true
  let date = get(e, 'date')
  if (date) curDate.value = date
  if (!date) date = curDate.value
  await Promise.all([
    getPointRecord(date),
    getPointRecordCount(date),
  ])
  loading.table = false
}

const getExportData = async () => {
  const dataContainer = []
  const payload = {
    shopId: shopId.value,
    start: pageStartIndex.value,
    limit: tableOptions.pageLimit,
    createStart: dayjs(curDate.value[0]).toDate(),
    createEnd: dayjs(curDate.value[1]).toDate(),
    origin: get(search, '.origin') || undefined,
    memberId: get(search, 'member.id') || undefined,
  }
  while (dataContainer.length < exportState.dataCount) {
    const [res, err] = await GetPointRecord(payload)
    if (err) {
      window.$message.error(err)
      return
    }
    dataContainer.push(...res)
    payload.start += payload.limit
  }
  return dataContainer
}

const formatExportData = () => {
  if (!exportState.exportData) return
  console.log(exportState.exportData)

  const formatData = []
  let count = 0
  exportState.exportData.forEach((item) => {
    const row = {
      姓名: get(item, 'Member.UserInfo.name') || '-',
      電話號碼: get(item, 'Member.UserInfo.phone') || '-',
      形式: get(originConfig, `${item.origin}.name`) || '-',
      點數: item.amount,
      建立時間: dateFormat(item.createdAt),
      備註: item.originMessage || '-',
    }
    formatData.push(row)
    count++
    exportState.percentage = (count / exportState.dataCount) * 100
  })
  const fileName = `點數報表_${dayjs(curDate.value[0]).format('YYYYMMDD-HHmm')}_${dayjs(curDate.value[1]).format('YYYYMMDD-HHmm')}`
  ExportExcel(formatData, '點數報表', fileName)
}

const prepareExport = async () => {
  resetExport()
  // 取得匯出數據數量
  exportState.dataCount = tableDataCount.value
  if (!exportState.dataCount) {
    exportState.error = true
    window.$message.warning('沒有資料可以匯出')
    return null
  }
  dialog.exporting = true
  exportState.exportting = true

  // get export data
  exportState.exportData = await getExportData()
  formatExportData()
}

const useStorePermission = computed(() => {
  return checkAction('admin.branch.adminUseStore')
})

onMounted(async () => {
  await refresh()
})
</script>

<style scoped lang="postcss">

</style>
